import { ButtonLooks, FontIcons, StandardButton, StandardButtonProps } from '@brandfolder/react';
import { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { AsideContext } from '@context/aside';

interface HeroEditProps extends Omit<StandardButtonProps, 'children'> {}

const UnstyledHeroEdit: FunctionComponent<HeroEditProps> = (props) => {
  const { setAutoFocusId, setShowSettings } = useContext(AsideContext);

  return (
    <StandardButton
      {...props}
      startIcon={FontIcons.Edit}
      look={ButtonLooks.Tertiary}
      onClick={() => {
        setShowSettings(true);
        setAutoFocusId('heroSrc');
      }}
    >
      Edit Image
    </StandardButton>
  );
};

const StyledHeroEdit = styled(UnstyledHeroEdit)``;

export const HeroEdit: FunctionComponent<HeroEditProps> = (props) => {
  return <StyledHeroEdit {...props} />;
};
