import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Canvas } from '@components/canvas';
import { Nav } from '@components/nav';

interface ContentProps extends HTMLAttributes<HTMLDivElement> {}

const UnstyledContent: FunctionComponent<ContentProps> = (props) => {
  return (
    <div
      {...props}
      className={classNames({
        content: true,
        [`${props?.className}`]: !!props?.className
      })}
    >
      <Nav />
      <Canvas />
    </div>
  );
};

const StyledContent = styled(UnstyledContent)`
  display: block;
  grid-template-areas: 'nav canvas';
  grid-template-columns: 400px minmax(0, 1fr);

  @media (min-width: 768px) {
    display: grid;
  }
`;

export const Content: FunctionComponent<ContentProps> = (props) => {
  return <StyledContent {...props} />;
};
