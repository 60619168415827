import { CmykColor, RgbColor } from '@brandfolder/react';

import { AssetDataColor } from '@typings/asset';

/**
 * Taken from potential PR to add HEX validation to Yup:
 * https://github.com/jquense/yup/pull/1411
 */
export const hexRegex = /^#([\da-f]{3}|[\da-f]{6})$/i;

/**
 * NOTE: boulder saves HEX without the leading hash symbol
 */
export const hexWithoutHashRegex = /^([\da-f]{3}|[\da-f]{6})$/i;

/**
 * Check if color asset has CMYK data
 * @param assetData AssetDataColor
 * @returns boolean
 */
export const getHasCmyk = (assetData?: AssetDataColor): assetData is AssetDataColor => {
  return !!assetData?.c && !!assetData?.m && !!assetData?.y && !!assetData?.k;
};

/**
 * Check if color asset has RGB data
 * @param assetData AssetDataColor
 * @returns boolean
 */
export const getHasRgb = (assetData?: AssetDataColor): assetData is AssetDataColor => {
  return !!assetData?.r && !!assetData?.g && !!assetData?.b;
};

/**
 * Create CMYK object from asset data
 * @param assetData AssetDataColor
 * @returns boolean
 */
export const convertDataToCmyk = (assetData: AssetDataColor): CmykColor => {
  return {
    c: Number(assetData.c),
    m: Number(assetData.m),
    y: Number(assetData.y),
    k: Number(assetData.k)
  };
};

/**
 * Create RGB object from asset data
 * @param assetData AssetDataColor
 * @returns boolean
 */
export const convertDataToRgb = (assetData: AssetDataColor): RgbColor => {
  return {
    r: Number(assetData.r),
    g: Number(assetData.g),
    b: Number(assetData.b)
  };
};
