import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Fragment, FunctionComponent, HTMLAttributes, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { BlockContext } from '@context/blocks';
import { OAuthContext } from '@context/oauth';
import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { BlockTypes } from '@enums/blocks';
import { Block } from '@typings/block';
import { getBlockDataIsSection } from '@utilities/block';
import { hashMatchesSection, scrollToSection } from '@utilities/scroll';

import { BlockRender } from '../block-render';

/**
 * These components are only needed when editing client-side, so they should not be
 * contributing to the JS page load, and instead can be loaded via code splitting.
 * Be sure to check `yarn build` after modifying.
 */
const CanvasDrop = dynamic(() => import('../../canvas/canvas-drop'), { ssr: false });

interface SectionRenderProps extends HTMLAttributes<HTMLDivElement> {
  block: Block;
}

const UnstyledSectionRender: FunctionComponent<SectionRenderProps> = (props) => {
  const { block, ...otherProps } = props;

  const { pageBlocks } = useContext(BlockContext);
  const { authed } = useContext(OAuthContext);
  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);

  const data = getBlockDataIsSection(block.data) ? block.data : null;

  useEffect(() => {
    if (!authed && hashMatchesSection(data?.id)) {
      /**
       * This handles scrolling to section when not editing
       * If this changes, also edit components/wysiwyg/tiny-mce-inline.tsx
       */
      scrollToSection(data?.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data) return null;

  const editing = editable && !preview;
  const sectionBlocks = pageBlocks.filter((b) => block.key === b.parentKey);

  return (
    <section
      {...otherProps}
      className={classNames({
        section: true,
        'section--editing': editing,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      data-testid={data.id}
      id={data.id}
    >
      {sectionBlocks.map((b, index) => {
        const { createdAt, deletedAt, position, type, updatedAt } = b;

        if (deletedAt) return null;

        const first = index === 0;
        const last = index === sectionBlocks.length - 1;
        const className = type === BlockTypes.Color ? 'canvas-drop--color' : undefined;
        const key = `${b.key}-${createdAt}-${updatedAt}-${position}-${index}`;

        if (first) {
          return (
            <Fragment key={key}>
              <BlockRender block={b} index={index} sectionId={data.id} />
              {first && last && <CanvasDrop parentKey={block.key} position={position + 1} />}
            </Fragment>
          );
        }

        return (
          <Fragment key={key}>
            <CanvasDrop className={className} parentKey={block.key} position={position} />
            <BlockRender block={b} index={index} sectionId={data.id} />
            {last && <CanvasDrop parentKey={block.key} position={position + 1} />}
          </Fragment>
        );
      })}
    </section>
  );
};

const StyledSectionRender = styled(UnstyledSectionRender)``;

export const SectionRender: FunctionComponent<SectionRenderProps> = (props) => {
  return <StyledSectionRender {...props} />;
};
