import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Fragment, FunctionComponent, HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';

import { BlockRender } from '@components/blocks';
import { AsideContext } from '@context/aside';
import { BlockContext } from '@context/blocks';
import { PageContext } from '@context/page';
import { PagesContext } from '@context/pages';
import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { BlockTypes } from '@enums/blocks';
import { getIsLastBlock, getIsOnlyBlock } from '@utilities/block';

/**
 * These components are only needed when editing client-side, so they should not be
 * contributing to the JS page load, and instead can be loaded via code splitting.
 * Be sure to check `yarn build` after modifying.
 */
const CanvasDrop = dynamic(() => import('./canvas-drop'), { ssr: false });

const PageTitle: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ children }) => {
  return <h1 className="canvas__page-title">{children}</h1>;
};

interface CanvasProps extends HTMLAttributes<HTMLDivElement> {}

const UnstyledCanvas: FunctionComponent<CanvasProps> = (props) => {
  const { setShowPages } = useContext(AsideContext);
  const { pageBlocks } = useContext(BlockContext);
  const { page } = useContext(PageContext);
  const { setEditingPage } = useContext(PagesContext);
  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);

  const only = getIsOnlyBlock(pageBlocks);

  const renderPageTitle = (): JSX.Element => {
    if (editable && !preview) {
      return (
        <PageTitle>
          <button
            className="canvas__page-edit"
            onClick={() => {
              setShowPages(true);
              setEditingPage(page);
            }}
            type="button"
          >
            {page?.name}
          </button>
        </PageTitle>
      );
    }
    return <PageTitle>{page?.name}</PageTitle>;
  };

  return (
    <div
      {...props}
      className={classNames({
        canvas: true,
        'canvas--editable': editable,
        'canvas--preview': preview,
        [`${props?.className}`]: !!props?.className
      })}
    >
      {renderPageTitle()}

      {pageBlocks.length === 0 && <CanvasDrop position={0} />}

      {pageBlocks.map((b, index) => {
        const { createdAt, deletedAt, parentKey, position, type, updatedAt } = b;

        if (deletedAt) return null;

        const className = type === BlockTypes.Color ? 'canvas-drop--color' : undefined;
        const first = index === 0;
        const last = getIsLastBlock(b, pageBlocks);
        const key = `${b.key}-${createdAt}-${updatedAt}-${position}-${index}`;

        return (
          <Fragment key={key}>
            {!parentKey && <CanvasDrop className={className} position={position} />}
            <BlockRender block={b} first={first} index={index} last={last} only={only} />
            {last && type !== BlockTypes.Section && (
              <CanvasDrop className={className} position={position + 1} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const StyledCanvas = styled(UnstyledCanvas)`
  grid-area: canvas;
  padding: 30px 30px 60px;
  overflow: hidden; // react-pdf

  @media (min-width: 768px) {
    padding: 45px 30px 60px;
  }

  .canvas__page-edit {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: left;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Canvas: FunctionComponent<CanvasProps> = (props) => {
  return <StyledCanvas {...props} />;
};
