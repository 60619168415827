import { BrandfolderHexColors, useDrag } from '@brandfolder/react';
import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, ReactNode, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AsideContext } from '@context/aside';
import { DragAndDropContext } from '@context/drag-and-drop';
import { LocaleContext } from '@context/locale';
import { PageContext } from '@context/page';
import { BlockDragAndDropFormats, BlockTypes } from '@enums/blocks';
import { BlockDragItem } from '@typings/block';
import { createBlockDragItem } from '@utilities/block';

interface BlockDragProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  type: BlockTypes;
}

const UnstyledBlockDrag: FunctionComponent<BlockDragProps> = (props) => {
  const { children, type, ...otherProps } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const { setShowBlocks } = useContext(AsideContext);
  const { setDragging } = useContext(DragAndDropContext);
  const { locale } = useContext(LocaleContext);
  const { page } = useContext(PageContext);

  const getData = (): BlockDragItem => {
    return createBlockDragItem({
      locale,
      pageKey: page?.key as string,
      type
    });
  };

  const { dragging } = useDrag({
    data: getData(),
    format: BlockDragAndDropFormats.BlockDrag,
    ref
  });

  useEffect(() => {
    setDragging(dragging);
  }, [dragging]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      {...otherProps}
      className={classNames({
        block__drag: true,
        'block__drag--dragging': dragging,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      draggable
      ref={ref}
      onDragEnd={() => {
        setShowBlocks(false);
      }}
    >
      {children}
    </div>
  );
};

const StyledBlockDrag = styled(UnstyledBlockDrag)`
  align-items: center;
  background-color: ${BrandfolderHexColors.DirtyMarshmallow};
  border-color: ${BrandfolderHexColors.GrayBorder};
  border-style: dashed;
  border-width: 2px;
  cursor: grab;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 20px;
  min-height: 80px;
  padding: 10px;
  text-align: center;
  transition: border-color 0.2s ease;

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${BrandfolderHexColors.GrayMediumDark};
  }

  &.block__drag--dragging {
    cursor: grabbing;
    opacity: 0.5;
    outline: 0;
  }
`;

export const BlockDrag: FunctionComponent<BlockDragProps> = (props) => {
  return <StyledBlockDrag {...props} />;
};
