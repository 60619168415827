import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Block } from '@typings/block';
import { getBlockDataIsImage } from '@utilities/block';
import { rewriteCdnHost } from '@utilities/cdn';

interface ImageRenderProps extends HTMLAttributes<HTMLDivElement> {
  block: Block;
}

const UnstyledImageRender: FunctionComponent<ImageRenderProps> = (props) => {
  const { block, ...otherProps } = props;

  const data = getBlockDataIsImage(block.data) ? block.data : null;

  if (!data) return null;

  /**
   * boulder lvh.me Fastly URL's return 403, `isDev` is a cheat for Brandguide v2 localhost
   * so that we can at least see the image locally
   */
  const isDev = process.env.NODE_ENV === 'development';
  const src = isDev ? data.thumbnailUrl : rewriteCdnHost(data.cdnUrl);

  return (
    <Image
      {...otherProps}
      alt={data.name || ''}
      className={classNames({
        image: true,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      height={data.height}
      loading="lazy"
      loader={(options) => {
        const url = new URL(options.src);
        const quality = url.searchParams.get('quality') || options.quality;

        /**
         * https://developer.fastly.com/reference/io/
         */
        url.searchParams.set('auto', url.searchParams.get('auto') || 'webp');
        url.searchParams.set('quality', `${quality || 50}`);
        url.searchParams.set('width', `${options.width}`);

        return `${url.origin}${url.pathname}${url.search}`;
      }}
      placeholder="empty"
      priority={false}
      src={src}
      style={{
        ...otherProps?.style,
        aspectRatio: data.width / data.height
      }}
      width={data.width}
    />
  );
};

const StyledImageRender = styled(UnstyledImageRender)`
  height: auto;
  margin-bottom: 24px;
  max-width: 100%;

  &:before {
    content: '';
    clear: both;
    display: table;
  }
`;

export const ImageRender: FunctionComponent<ImageRenderProps> = (props) => {
  return <StyledImageRender {...props} />;
};
