import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { HtmlFontLoader } from '@components/blocks/html/html-font-loader';

import { Block } from '@typings/block';
import { getBlockDataIsHtml } from '@utilities/block';

interface HtmlRenderProps extends HTMLAttributes<HTMLDivElement> {
  block: Block;
}

const UnstyledHtmlRender: FunctionComponent<HtmlRenderProps> = (props) => {
  const { block, ...otherProps } = props;

  const data = getBlockDataIsHtml(block.data) ? block.data : null;

  if (!data) return null;

  return (
    <HtmlFontLoader block={block}>
      <div
        {...otherProps}
        className={classNames({
          text: true,
          [`${otherProps?.className}`]: !!otherProps?.className
        })}
        dangerouslySetInnerHTML={{
          __html: data.html
        }}
        data-testid="html-render"
      />
    </HtmlFontLoader>
  );
};

const StyledHtmlRender = styled(UnstyledHtmlRender)`
  margin: 0 0 24px 0;

  &:before {
    content: '';
    clear: both;
    display: table;
  }
`;

export const HtmlRender: FunctionComponent<HtmlRenderProps> = (props) => {
  return <StyledHtmlRender {...props} />;
};
