import { Dispatch, HTMLAttributes, SetStateAction, useRef } from 'react';

export interface UseDraggedOptions {
  dragged: boolean;
  setDragged: Dispatch<SetStateAction<boolean>>;
}

export type UseDraggedProps = Pick<
  HTMLAttributes<HTMLElement>,
  'onDragEnter' | 'onDragOver' | 'onDragLeave'
>;

export interface UseDraggedReturn {
  draggedProps: UseDraggedProps;
}

/**
 * A hook for adding drag events to set dragged to context
 * @param options UseDraggedOptions
 * @returns UseDraggedReturn
 */
export const useDragged = (options: UseDraggedOptions): UseDraggedReturn => {
  const { dragged, setDragged } = options;

  const dragEnterTarget = useRef<EventTarget | null>(null);

  return {
    draggedProps: {
      onDragEnter: (e) => {
        e.preventDefault();
        dragEnterTarget.current = e.target;
        setDragged(true);
      },
      onDragOver: (e) => {
        e.preventDefault();
        if (!dragged) {
          setDragged(true);
        }
      },
      onDragLeave: (e) => {
        e.preventDefault();
        const escPressed = e.screenX === 0 && e.screenY === 0;
        if (e.target === dragEnterTarget.current && !escPressed) {
          setDragged(false);
        }
      }
    }
  };
};
