import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';

import { Hero } from '@components/hero';
import { Content, Main } from '@components/main';
import { Page } from '@components/page';

/**
 * These components are only needed when editing client-side, so they should not be
 * contributing to the JS page load, and instead can be loaded via code splitting.
 * Be sure to check `yarn build` after modifying.
 */
const Aside = dynamic(() => import('../aside/aside'), { ssr: false });
const Header = dynamic(() => import('../header/header'), { ssr: false });

interface AppProps {}

export const App: FunctionComponent<AppProps> = () => {
  return (
    <Page>
      <Header />
      <Aside />
      <Main>
        <Hero />
        <Content />
      </Main>
    </Page>
  );
};
