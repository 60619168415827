import { colord } from 'colord';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { App } from '@components/app';
import { PageHead } from '@components/page';
import { BlockContext } from '@context/blocks';
import { BrandguideContext } from '@context/brandguide';
import { ConfigContext } from '@context/config';
import { GoogleWebfontsContext } from '@context/google-webfonts';
import { LocaleContext } from '@context/locale';
import { OAuthContext } from '@context/oauth';
import { PageContext } from '@context/page';
import { PermissionLevelContext } from '@context/permission-level';
import { ThemeContext } from '@context/theme';
import { WhitelabelContext } from '@context/whitelabel';
import { Locales } from '@enums/locales';
import { SiteProps } from '@typings/site';
import { Theme } from '@typings/theme';
import { WhitelabelScope } from '@typings/whitelabel';
import { initializeClientLogger } from '@utilities/client-logger';
import { getFonts } from '@utilities/font';

export const SiteBrandguide: FunctionComponent<SiteProps> = (props) => {
  const {
    authed,
    blocks,
    brandfolder,
    brandguide,
    collection,
    googleWebfonts,
    isCname,
    locale = Locales.EnUS,
    page,
    pages,
    publicRuntimeConfig,
    theme: serverSidePropsTheme
  } = props;

  const router = useRouter();

  const { setBlocks, setInitialBlocks } = useContext(BlockContext);
  const {
    brandguide: b,
    setBrandfolder,
    setBrandguide,
    setCollection,
    setIsCname
  } = useContext(BrandguideContext);
  const { setPublicRuntimeConfig } = useContext(ConfigContext);
  const { setLocale } = useContext(LocaleContext);
  const { setAuthed } = useContext(OAuthContext);
  const { page: p, setPage, setPages } = useContext(PageContext);
  const { editable } = useContext(PermissionLevelContext);
  const { setWhitelabelScope } = useContext(WhitelabelContext);
  const {
    BRANDFOLDER_BOULDER_URL,
    DD_CLIENT_TOKEN,
    ENVIRONMENT,
    GETTY_BOULDER_URL,
    GIT_VERSION,
    NEXTAUTH_URL
  } = publicRuntimeConfig;

  const [isLight, setIsLight] = useState(true);
  const [theme, setTheme] = useState<Theme>(serverSidePropsTheme);

  useEffect(() => {
    setAuthed(authed);
    setBlocks(blocks);
    setBrandfolder(brandfolder);
    setBrandguide(brandguide);
    setCollection(collection);
    setInitialBlocks(blocks);
    setIsCname(isCname);
    setLocale(locale);
    setPage(page);
    setPages(pages);
    setPublicRuntimeConfig(publicRuntimeConfig);
    setWhitelabelScope(brandguide?.whitelabel || WhitelabelScope.Brandfolder);

    // removes access_token and refresh_token query params when signing in
    if (router.query.access_token && router.query.refresh_token) {
      router.replace(`${window.location.origin}${window.location.pathname}`, undefined, {
        shallow: true
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const allowedTracingUrls = [BRANDFOLDER_BOULDER_URL, NEXTAUTH_URL, GETTY_BOULDER_URL];
    initializeClientLogger({
      allowedTracingUrls,
      enableSessionRecordings: editable,
      clientToken: DD_CLIENT_TOKEN,
      env: ENVIRONMENT,
      version: GIT_VERSION
    });
  }, [editable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLight(colord(theme.body.backgroundColor).isLight());
  }, [theme.body.backgroundColor]);

  return (
    <ThemeContext.Provider value={{ isLight, theme, setTheme }}>
      <GoogleWebfontsContext.Provider value={{ fonts: getFonts(googleWebfonts), googleWebfonts }}>
        <PageHead brandguide={b || brandguide} page={p || page} theme={theme} />
        <App />
      </GoogleWebfontsContext.Provider>
    </ThemeContext.Provider>
  );
};
