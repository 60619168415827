import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';

import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { ThemeContext } from '@context/theme';

import { HeroEdit } from './hero-edit';
import { HeroPreview } from './hero-preview';

interface HeroProps extends HTMLAttributes<HTMLDivElement> {}

const UnstyledHero: FunctionComponent<HeroProps> = (props) => {
  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);
  const { theme } = useContext(ThemeContext);

  return (
    <div
      {...props}
      className={classNames({
        hero: true,
        [`${props?.className}`]: !!props?.className
      })}
      data-testid="hero"
      style={{
        backgroundImage: `url(${theme.image.heroSrc})`
      }}
    >
      {!preview && editable && (
        <div className="hero-buttons">
          <HeroEdit />
          <HeroPreview />
        </div>
      )}
    </div>
  );
};

const StyledHero = styled(UnstyledHero)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 500px;
  min-height: 200px;
  position: relative;

  @media (min-width: 768px) {
    aspect-ratio: 2880/720;
    max-height: none;
    min-height: 0;
  }

  .hero-buttons {
    position: absolute;
    top: 36px;
    right: 36px;

    .bf-button__standard {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
`;

export const Hero: FunctionComponent<HeroProps> = (props) => {
  return <StyledHero {...props} />;
};
