import { BrandfolderHexColors, ButtonLooks, StandardButton } from '@brandfolder/react';
import classNames from 'classnames';
import {
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { Toaster } from 'react-hot-toast';
import styled from 'styled-components';

import { DragAndDropContext } from '@context/drag-and-drop';
import { PermissionLevelContext } from '@context/permission-level';
import { ThemeContext } from '@context/theme';
import { useDragged } from '@hooks/use-dragged';

interface MainProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const UnstyledMain: FunctionComponent<MainProps> = (props) => {
  const { children, ...otherProps } = props;

  const { dragged, setDragged } = useContext(DragAndDropContext);
  const { isLight } = useContext(ThemeContext);
  const { editable } = useContext(PermissionLevelContext);
  const { draggedProps } = useDragged({ dragged, setDragged });

  const [className, setClassName] = useState('dark-toast');

  useEffect(() => {
    setClassName(isLight ? 'light-toast' : 'dark-toast');
  }, [isLight]);

  return (
    <main
      {...otherProps}
      {...draggedProps}
      className={classNames({
        main: true,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      id="main"
    >
      {children}
      <StandardButton
        className="ketch"
        look={ButtonLooks.Default}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.semaphore) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.semaphore.push(['showPreferences', { showRightsTab: false }]);
          }
        }}
        type="button"
      >
        Cookie Preferences
      </StandardButton>
      {editable && (
        <Toaster
          containerClassName="toaster"
          position="bottom-center"
          toastOptions={{
            className: className
          }}
        />
      )}
    </main>
  );
};

const StyledMain = styled(UnstyledMain)`
  grid-area: main;
  min-width: 0;
  position: relative;

  .ketch {
    bottom: 12px;
    left: 12px;
    min-height: 0;
    padding: 6px 8px;
    position: absolute;
    z-index: 2;
  }

  .light-toast {
    background-color: ${BrandfolderHexColors.White};
    color: ${BrandfolderHexColors.Text};
    font-family: 'Circular-Medium';
    font-weight: 500;
  }

  .dark-toast {
    background-color: ${BrandfolderHexColors.Title};
    color: ${BrandfolderHexColors.White};
    font-family: 'Circular-Medium';
    font-weight: 500;
  }
`;

export const Main: FunctionComponent<MainProps> = (props) => {
  return <StyledMain {...props} />;
};
