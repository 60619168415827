import { ListboxOption } from '@brandfolder/react';

import { FONT_FAMILY_FORMATS } from '@constants/font';
import { FontCategories } from '@enums/font';
import { Font } from '@typings/font';
import { GoogleWebfontFamily } from '@typings/google-webfont';

/**
 * Get the computed `font-family` of an element.
 * @param element Element
 * @returns string
 */
export const getComputedFontFamily = (element: Element): string => {
  if (typeof window === 'undefined') return '';
  return window.getComputedStyle(element).getPropertyValue('font-family');
};

/**
 * Transforms Google Font categories to CSS `font-family` categories.
 *
 * @param category string | undefined
 * @returns FontCategories
 */
export const getFontCategory = (category?: string): FontCategories => {
  if (Object.values(FontCategories).includes(category as FontCategories)) {
    return category as FontCategories;
  }
  if (category === 'handwriting') return FontCategories.Cursive;
  return FontCategories.SansSerif;
};

/**
 * CSS spec recommends quotes around `font-family` names that contains spaces and dashes.
 * Adding quotes around `font-family` names that don't contain spaces or dashes is harmless.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-family#valid_family_names
 *
 * @param family string
 * @returns string
 */
export const addFontFamilyQuotes = (family: string): string => {
  return `"${family}"`;
};

/**
 * Get the first `font-family` from CSS `font-family` value.
 *
 * @param family string
 * @returns string
 */
export const getFirstFontFamily = (family: string): string => {
  return family.split(',')[0];
};

/**
 * Remove quotes from `font-family` name.
 *
 * @param family string
 * @returns string
 */
export const stripFontFamilyQuotes = (family: string): string => {
  return family.trim().replaceAll(/["']/g, '');
};

/**
 * Get all the fonts from Google Fonts and TinyMCE.
 *
 * @param googleWebfonts GoogleWebfontFamily[]
 * @returns Font[]
 */
export const getFonts = (googleWebfonts: GoogleWebfontFamily[]): Font[] => {
  return [
    ...googleWebfonts.map((font) => ({
      category: getFontCategory(font.category),
      family: font.family
    })),
    ...FONT_FAMILY_FORMATS
  ].sort((a, b) => a.family.localeCompare(b.family));
};

/**
 * Search fonts programmatically.
 *
 * @param fontFamilies Font[]
 * @param search string | undefined
 * @returns Font[]
 */
export const searchFonts = (fontFamilies: Font[], search?: string): Font[] => {
  if (!search) return fontFamilies;
  return fontFamilies.filter((font) => font.family.toLowerCase().includes(search.toLowerCase()));
};

/**
 * Extract a list of fonts from HTML code
 *
 * @param html string
 * @returns string[]
 */
export const extractFontFamiliesFromHtml = (html: string): string[] => {
  const fontFamilies = [];
  const regex = /font-family:\s*([^;]+)/gi;

  let match;
  while ((match = regex.exec(html)) !== null) {
    fontFamilies.push(stripFontFamilyQuotes(match[1]));
  }

  return fontFamilies;
};

/**
 * Create combobox options of fonts for global typeface settings
 * @returns ListboxOption[]
 */
export const createTypefaceOptions = (): ListboxOption[] => {
  return FONT_FAMILY_FORMATS.map((font) => ({
    children: font.family,
    key: font.family,
    search: font.family,
    value: font.family
  }));
};
