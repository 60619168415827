import { ButtonLooks, StandardButton, StandardButtonProps } from '@brandfolder/react';
import { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { PreviewContext } from '@context/preview';

interface HeroPreviewProps extends Omit<StandardButtonProps, 'children'> {}

const UnstyledHeroPreview: FunctionComponent<HeroPreviewProps> = (props) => {
  const { setPreview } = useContext(PreviewContext);

  return (
    <StandardButton {...props} look={ButtonLooks.Tertiary} onClick={() => setPreview(true)}>
      Preview
    </StandardButton>
  );
};

const StyledHeroPreview = styled(UnstyledHeroPreview)``;

export const HeroPreview: FunctionComponent<HeroPreviewProps> = (props) => {
  return <StyledHeroPreview {...props} />;
};
