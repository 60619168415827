import { createContext } from 'react';

import { Font } from '@typings/font';
import { GoogleWebfontFamily } from '@typings/google-webfont';

interface GoogleWebfontsContextState {
  fonts: Font[];
  googleWebfonts: GoogleWebfontFamily[];
}

const defaultFontsState: GoogleWebfontsContextState = {
  fonts: [],
  googleWebfonts: []
};

export const GoogleWebfontsContext = createContext<GoogleWebfontsContextState>(defaultFontsState);
