import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Block, BlockDoc } from '@typings/block';
import { getBlockDataIsDoc } from '@utilities/block';

export const officeEmbedUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';

interface DocRenderProps extends HTMLAttributes<HTMLDivElement> {
  block: Block;
  data?: BlockDoc;
}

interface InternalDocRenderProps extends DocRenderProps {
  data: BlockDoc;
}

const UnstyledDocRender: FunctionComponent<InternalDocRenderProps> = (props) => {
  const { block, data, ...otherProps } = props;
  const { key, type } = block;

  const { cdnUrl, name } = data;

  return (
    <div
      {...otherProps}
      className={classNames({
        docs: true,
        [`docs--${type}`]: !!type,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      id={key}
    >
      <iframe
        className={classNames({
          docs__iframe: true,
          [`docs__iframe--${type}`]: !!type
        })}
        src={`${officeEmbedUrl}${cdnUrl}`}
        title={name}
      ></iframe>
    </div>
  );
};

const StyledDocRender = styled(UnstyledDocRender)`
  .docs__iframe {
    aspect-ratio: ${(props) => props.data.width} / ${(props) => props.data.height};
    border: 0;
    height: auto;
    width: 100%;
  }

  .docs__iframe--excel {
    max-height: calc(100vh - 40px);

    @media (min-height: 768px) {
      max-height: calc(100vh - 60px);
    }

    @media (min-height: 1024px) {
      max-height: calc(100vh - 80px);
    }
  }
`;

/**
 * Docs renderer is able to handle Excel (.xls, .xlsx) PowerPoint (.ppt, .pptx), and Word (.doc, .docx) files.
 *
 * This uses the undocumented Office Online Viewer to render the files with `iframe`'s (which is also used by Boulder).
 *
 * @param props DocRenderProps
 * @returns FunctionComponent<DocRenderProps>
 */
export const DocRender: FunctionComponent<DocRenderProps> = (props) => {
  const { block, ...otherProps } = props;

  const data = getBlockDataIsDoc(block.data) ? block.data : null;

  if (!data) return null;

  return <StyledDocRender {...otherProps} block={block} data={data} />;
};
