import { datadogRum, RumEvent } from '@datadog/browser-rum';

type ClientLogger = {
  allowedTracingUrls: string[];
  enableSessionRecordings: boolean;
  clientToken?: string;
  env?: string;
  version?: string;
};

export const shouldDiscardError = (event: RumEvent): boolean | void => {
  const ignoredErrors = [
    'aborted',
    'exceeded the quota',
    'failed to fetch',
    'ketch',
    'loading chunk',
    'load failed',
    'minified react',
    'quota has been exceeded',
    'removechild',
    'script error'
  ];

  return (
    event.type === 'error' &&
    ignoredErrors.some((ignoredError) => event.error.message.toLowerCase().includes(ignoredError))
  );
};

export const initializeClientLogger = (clientLogger: ClientLogger): void => {
  const { allowedTracingUrls, clientToken, enableSessionRecordings, env, version } = clientLogger;
  if (clientToken && env) {
    // Cloud Run is making an internal request so the url is http and not https, this is an attempt for RUM correlation
    const transformedTracingUrls = allowedTracingUrls.map((url) => url.replace('https', 'http'));

    datadogRum.init({
      allowedTracingUrls: [...allowedTracingUrls, ...transformedTracingUrls],
      applicationId: '72f03cda-00b2-4f17-9c83-10413c96a03b',
      beforeSend: (event) => {
        if (shouldDiscardError(event)) {
          return false;
        }
        return true;
      },
      clientToken,
      defaultPrivacyLevel: 'mask',
      env,
      service: 'brandguide-v2',
      sessionReplaySampleRate: 100,
      silentMultipleInit: true,
      site: 'datadoghq.com',
      startSessionReplayRecordingManually: true,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version
    });

    if (enableSessionRecordings) {
      datadogRum.startSessionReplayRecording();
    }
  }
};
