import {
  BrandfolderHexColors,
  StandardColorSwatch,
  StandardColorSwatchProps
} from '@brandfolder/react';
import classNames from 'classnames';
import { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { ThemeContext } from '@context/theme';
import { AssetDataColor } from '@typings/asset';
import { Block } from '@typings/block';
import { getBlockDataIsColor } from '@utilities/block';
import { convertDataToCmyk, convertDataToRgb, getHasCmyk, getHasRgb } from '@utilities/color';

export interface ColorRenderProps extends Omit<StandardColorSwatchProps, 'colors' | 'labels'> {
  aside?: boolean;
  assetData?: AssetDataColor;
  block?: Block;
}

const UnstyledColorRender: FunctionComponent<ColorRenderProps> = (props) => {
  const { aside, assetData, block, id, name, ...otherProps } = props;

  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);
  const { theme } = useContext(ThemeContext);

  const blockData = block && getBlockDataIsColor(block.data) ? block.data : null;
  const data = assetData || blockData?.data;

  if (!data) return null;

  const editing = editable && !preview;

  return (
    <StandardColorSwatch
      {...otherProps}
      className={classNames({
        color: true,
        'bf-color-swatch__aside': aside,
        'bf-color-swatch__editing': editing,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      colors={{
        ...(getHasCmyk(data) && {
          cmyk: convertDataToCmyk(data)
        }),
        hex: data.hex || '',
        ...(getHasRgb(data) && {
          rgb: convertDataToRgb(data)
        })
      }}
      id={id}
      labels={{
        copiedLabel: 'Copied',
        copyLabel: 'Copy'
      }}
      name={name || blockData?.name}
      showCmyk={block && theme.color.displayCmyk}
      showEmptyCmyk={aside}
      showEmptyHex={aside}
      showEmptyRgb={aside}
      showHex={block && theme.color.displayHex}
      showHsl={false}
      showRgb={block && theme.color.displayRgb}
      spanProps={{
        className: classNames({
          'bf-color-swatch__swatch--white': data.hex?.toLowerCase().includes('ffffff')
        })
      }}
    />
  );
};

const StyledColorRender = styled(UnstyledColorRender)`
  margin: 0 24px 24px 0;
  width: 180px;

  &:not(.bf-color-swatch__editing) {
    float: left;
  }

  &.bf-color-swatch__aside {
    margin: 0;
    width: 100%;

    .bf-text__standard {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .bf-color-swatch__swatch--white {
    border: 1px solid ${BrandfolderHexColors.GrayBorder};
  }

  .bf-heading__standard {
    color: ${({ aside }) => (aside ? BrandfolderHexColors.Text : 'var(--body-color)')};
    font-family: ${({ aside }) => (aside ? 'Circular-Bold' : 'var(--font-family)')};
    font-weight: 500;
  }

  .bf-text__standard {
    color: ${({ aside }) => (aside ? BrandfolderHexColors.Text : 'var(--body-color)')};
    font-family: ${({ aside }) => (aside ? 'Circular-Book' : 'var(--font-family)')};
  }
`;

export const ColorRender: FunctionComponent<ColorRenderProps> = (props) => {
  const { aside = false, ...otherProps } = props;
  return <StyledColorRender {...otherProps} aside={aside} />;
};
